@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import 'nebular-icons/css/nebular-icons.css';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

nb-spinner {
  z-index: 998 !important;
}

.mat-cell,
.mat-header-cell {
  padding: 1rem 0.5rem !important;
}

.tip {
  color: gray;

  cursor: pointer;
  transition: color 0.4s;

  &:hover {
    color: #3366ff;
  }
}

.character-limiter {
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-size-trainings {
  > thead th,
  > tbody td {
    width: 16.67% !important;
  }
}

.text-align-right {
  text-align: right !important;
}

.pr-actions {
  &-small {
    padding-right: 1.5rem !important;
  }

  &-large {
    padding-right: 5rem !important;

    @media (max-width: 1320px) {
      padding-right: 2.5rem !important;
    }
  }
}

.collapsed-menu-context {
  &::after {
    content: '';
    border-style: solid;
    border-width: 5px 6px 5px 0;
    border-color: transparent white transparent transparent;
    position: absolute;
    top: 15px;
    left: -5px;
  }

  nb-context-menu nb-menu {
    .menu-item {
      padding: 0.2rem 0.5rem 0 0.5rem;
      margin: 0;

      &:last-of-type {
        padding-bottom: 0.5rem;
      }

      &.menu-group {
        color: #4d3093;
        margin: 0;
        padding: 0.5rem;
        font-weight: bold;
      }

      > a {
        padding: 0 !important;
        margin: 0;

        > span {
          text-align: left;
          padding: 0.2rem 0.8rem {
            left: 1rem;
          }
        }
      }
    }
  }
}

.logout-menu-context {
  &::after {
    content: '';
    border-style: solid;
    border-width: 5px 6px 5px 0;
    border-color: transparent white transparent transparent;
    position: absolute;
    top: calc(50% - 4px);
    left: -5px;
  }

  nb-context-menu nb-menu {
    .menu-item {
      padding: 0.5rem;
      margin: 0;

      > a {
        padding: 0;
        margin: 0;

        > span {
          text-align: center;
          padding: 0.2rem 0 !important;
          margin: 0;
        }
      }
    }
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(200, 200, 200, 1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 3px rgba(90, 90, 90, 0.7);
  }
}

// Keyframes
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
